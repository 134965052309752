/* QuizPage.css */

/* Add padding to the .quiz container */
.quiz {
  padding: 20px; /* Adjust the value as needed */
}

/* Add padding or margin to the .notes container */
.notes {
  padding: 50px; /* Adjust the value as needed */
  background-color: #f9f9f9; /* Optional: Add background color */
  border-radius: 8px; /* Optional: Add rounded corners */
}

/* Optionally, add padding or margin to the .progress-bar-container */
.progress-bar-container {
  padding: 10px 0; /* Adjust the value as needed */
  margin-bottom: 20px; /* Space below the progress bar */
}

/* Style the quiz buttons */
.quiz-btn {
  padding: 10px 15px; /* Adjust the padding */
  margin-bottom: 10px; /* Space between quiz buttons */
}

/* Style the modal content */
.modal-content {
  padding: 20px; /* Add padding inside the modal */
}

/* Other necessary styles */
ul {
  padding-left: 20px; /* Indent the list items slightly */
}

.question-block {
  margin-bottom: 20px; /* Add space between questions */
}

.option {
  margin-bottom: 5px; /* Add space between options */
}

.correct {
  color: green;
  font-weight: bold;
}

.incorrect {
  color: red;
  font-weight: bold;
}

.feedback {
  display: block;
  margin-top: 5px;
}

.progress-bar-container {
  width: 100%;
  background-color: #e0e0df;
  border-radius: 25px;
  padding: 3px;
  margin: 20px 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.progress-bar {
  height: 30px;
  width: 0;
  background-color: #0fb7c4;
  border-radius: 25px;
  text-align: center;
  line-height: 30px;
  color: black;
  font-weight: bold;
  transition: width 0.4s ease-in-out;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.modal-content h2 {
  margin-top: 0;
}

.modal-content button {
  margin-top: 20px;
}

.styled-heading {
  font-weight: bold;
  font-size: 1.5em; /* Adjust the font size as needed */
  color: #2c5494; /* Change the color if desired */
}

.styled-subheading {
  font-weight: bold;
  color: #2c5494; /* Example color for subheadings */
}

.styled-topics {
  font-weight: bold;
  font-size: 1.5em; /* Adjust the font size as needed */
  color: black; /* Change the color if desired */
}

.image-style-0 {
  display: block;
  margin: 0 auto;
  width: 600px;
  height: auto;
}

.image-style-1 {
  display: block;
  margin: 0 auto;
  width: 600px;
  height: auto;
}

.image-style-2 {
  width: 400px;
  height: auto;
}

.image-style-3 {
  width: 300px;
  height: auto;
  margin: 0 auto;
}

.image-style-4 {
  display: block;
  margin: 0 auto;
  width: 600px;
  height: auto;
}

.image-style-5 {
  display: block;
  margin: 0 auto;
  width: 400px;
  height: auto;
}

.image-style-6 {
  display: block;
  margin: 0 auto;
  width: 400px;
  height: auto;
}

.image-style-7 {
  display: block;
  margin: 0 auto;
  width: 400px;
  height: auto;
}

.image-style-8 {
  display: block;

  width: 200px;
  height: auto;
}

/* Mobile styles */
@media (max-width: 600px) {
  .content-image {
    width: 100%;
    height: auto;
    margin: 0 auto;
    display: block;
  }
}

/* Add more styles as needed */

/* From Uiverse.io by alexruix */
.loader {
  width: 80px;
  height: 50px;
  position: relative;
}

.loader-text {
  position: absolute;
  top: 0;
  padding: 0;
  margin: 0;
  color: black;
  animation: text_713 3.5s ease both infinite;
  font-size: 0.8rem;
  letter-spacing: 1px;
  font-weight: bold;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.load {
  background-color: #94d500;
  border-radius: 50px;
  display: block;
  height: 16px;
  width: 16px;
  bottom: 0;
  position: absolute;
  transform: translateX(64px);
  animation: loading_713 3.5s ease both infinite;
}

.load::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: #94d500;
  border-radius: inherit;
  animation: loading2_713 3.5s ease both infinite;
}

@keyframes text_713 {
  0% {
    letter-spacing: 1px;
    transform: translateX(0px);
  }

  40% {
    letter-spacing: 2px;
    transform: translateX(26px);
  }

  80% {
    letter-spacing: 1px;
    transform: translateX(32px);
  }

  90% {
    letter-spacing: 2px;
    transform: translateX(0px);
  }

  100% {
    letter-spacing: 1px;
    transform: translateX(0px);
  }
}

@keyframes loading_713 {
  0% {
    width: 16px;
    transform: translateX(0px);
  }

  40% {
    width: 100%;
    transform: translateX(0px);
  }

  80% {
    width: 16px;
    transform: translateX(64px);
  }

  90% {
    width: 100%;
    transform: translateX(0px);
  }

  100% {
    width: 16px;
    transform: translateX(0px);
  }
}

@keyframes loading2_713 {
  0% {
    transform: translateX(0px);
    width: 16px;
  }

  40% {
    transform: translateX(0%);
    width: 80%;
  }

  80% {
    width: 100%;
    transform: translateX(0px);
  }

  90% {
    width: 80%;
    transform: translateX(15px);
  }

  100% {
    transform: translateX(0px);
    width: 16px;
  }
}

/* From Uiverse.io by doniaskima */
.loaderquiz {
  width: fit-content;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  background: radial-gradient(circle closest-side, #000 94%, #0000)
    right/calc(200% - 1em) 100%;
  animation: l24 1s infinite alternate linear;
}

.loaderquiz::before {
  content: "Loading...";
  line-height: 1em;
  color: #0000;
  background: inherit;
  background-image: radial-gradient(circle closest-side, #fff 94%, #000);
  -webkit-background-clip: text;
  background-clip: text;
}

@keyframes l24 {
  100% {
    background-position: left;
  }
}

.clearfix {
  clear: both; /* Ensure content starts below floated images */
}

/* Optional: Add padding and margin adjustments */
.clearfix-heading {
  margin-top: 20px;
}

/* Ensure that styled headings don't get mixed up with images */
.styled-heading {
  display: block;
  clear: both;
  margin-top: 20px; /* Adjust spacing as needed */
}

/* Float images to the left and add margin */
.image-style {
  float: left;
  margin: 10px 20px 10px 0;
  max-width: 45%; /* Ensure images don't take too much space */
}

/* Handle mobile responsiveness */
@media (max-width: 600px) {
  .image-style {
    float: none; /* Remove float on small screens */
    margin: 0 auto;
    display: block;
    width: 100%; /* Images take full width */
  }
}

/* Apply float to images so text can flow around them */
.image-style-0,
.image-style-1,
.image-style-2,
.image-style-3,
.image-style-4,
.image-style-5,
.image-style-6,
.image-style-7,
.image-style-8 {
  float: left; /* Float images to the left */
  margin: 10px 20px 10px 0; /* Add margin to create space between image and text */
  max-width: 50%; /* Ensure images don't take up too much space */
}

/* Clear floats if needed to avoid overlap */
.clearfix::after {
  content: "";
  display: table;
  clear: both;
}

/* Congratulatory Message Styles */

.completion-message {
  background-color: #e6ffe6;
  border: 2px solid #4caf50;
  border-radius: 10px;
  padding: 20px;
  margin: 20px 0;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.completion-message:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.completion-message h3 {
  color: #4caf50;
  margin-top: 0;
  font-size: 24px;
  font-weight: bold;
}

.completion-message p {
  color: #333;
  font-size: 18px;
  margin: 10px 0;
}

.badge {
  display: inline-block;
  background-color: #4caf50;
  color: #000;
  padding: 8px 15px;
  border-radius: 25px;
  font-weight: bold;
  margin-top: 15px;
  font-size: 14px;
  transition: all 0.3s ease;
}

.badge:hover {
  transform: scale(1.05);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.badge span[role="img"] {
  margin-right: 8px;
  font-size: 20px;
}

.achievement-badge {
  border: 2px solid #4caf50;
  padding: 20px;
  background-color: #f0f0f0;
  text-align: center;
  margin-top: 20px;
}

.achievement-badge h2 {
  color: #4caf50;
  font-size: 24px;
}

.achievement-badge .badge img {
  width: 100px;
  height: 100px;
}

.achievement-badge .badge p {
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
}

/* Media query for smaller screens */
@media (max-width: 600px) {
  .image-style-0,
  .image-style-1,
  .image-style-2,
  .image-style-3,
  .image-style-4,
  .image-style-5,
  .image-style-6,
  .image-style-7,
  .image-style-8 {
    float: none; /* Remove float for smaller screens */
    display: block; /* Images and text stack vertically */
    margin: 10px auto; /* Center images */
    width: 100%; /* Images take full width */
  }
}
