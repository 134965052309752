/* @tailwind base;
@tailwind components;
@tailwind utilities; */
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700&display=swap");

:root {
  --main-color: #94d500;
  --red: #e74c3c;
  --orange: #f39c12;
  --light-color: #888;
  --light-bg: #eee;
  --black: #2c3e50;
  --white: #fff;
  --border: 0.1rem solid rgba(0, 0, 0, 0.2);
}

* {
  font-family: "Nunito", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  border: none;
  text-decoration: none;
}

*::selection {
  background-color: var(--main-color);
  color: #fff;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
}

html::-webkit-scrollbar {
  width: 1rem;
  height: 0.5rem;
}

html::-webkit-scrollbar-track {
  background-color: transparent;
}

html::-webkit-scrollbar-thumb {
  background-color: var(--main-color);
}

body {
  background-image: url("../../assets/alex-lvrs-Md6_qA-BMis-unsplash.jpg");
  background-size: cover;
  background-attachment: fixed;
  padding-left: 30rem;
}

body.dark {
  --light-color: #aaa;
  --light-bg: #333;
  --black: #fff;
  --white: #222;
  --border: 0.1rem solid rgba(255, 255, 255, 0.2);
}

body.active {
  padding-left: 0;
}

section {
  padding: 2rem;
  margin: 0 auto;
  max-width: 1200px;
}

.heading {
  font-size: 2.5rem;
  color: var(--black);
  margin-bottom: 2.5rem;
  border-bottom: var(--border);
  padding-bottom: 1.5rem;
  text-transform: capitalize;
}

.inline-btn,
.inline-option-btn,
.inline-delete-btn,
.btn,
.delete-btn,
.option-btn {
  border-radius: 0.5rem;
  color: #fff;
  font-size: 1.8rem;
  cursor: pointer;
  text-transform: capitalize;
  padding: 1rem 3rem;
  text-align: center;
  margin-top: 1rem;
}

.btn,
.delete-btn,
.option-btn {
  display: block;
  width: 100%;
}

.inline-btn,
.inline-option-btn,
.inline-delete-btn {
  display: inline-block;
}

.btn {
  background-color: var(--main-color);
}
.inline-btn {
  background-color: #e74c3c;
}

.option-btn,
.inline-option-btn {
  background-color: #000;
}

.delete-btn,
.inline-delete-btn {
  background-color: var(--red);
}

.inline-btn:hover,
.inline-option-btn:hover,
.inline-delete-btn:hover,
.btn:hover,
.delete-btn:hover,
.option-btn:hover {
  background-color: #94d500;
  color: var(--white);
}

.flex-btn {
  display: flex;
  gap: 1rem;
}

.header {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--white);
  z-index: 1000;
  border-bottom: var(--border);
}

.header .flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 1.5rem 2rem;
}

.logo {
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 10px;
}

.logo img {
  width: 100%;
  height: auto;
  background: none;
}

.header .flex .search-form {
  width: 50rem;
  border-radius: 0.5rem;
  background-color: var(--light-bg);
  padding: 1.5rem 2rem;
  display: flex;
  gap: 2rem;
}

.header .flex .search-form input {
  width: 100%;
  font-size: 1.8rem;
  color: var(--black);
  background: none;
}

.header .flex .search-form button {
  background: none;
  font-size: 2rem;
  cursor: pointer;
  color: var(--black);
}

.dark-mode {
  background-color: #121212; /* Dark background color */
  color: #ffffff; /* White text color */
}

/* Additional dark mode styles for specific elements if needed */
.dark-mode .header {
  background-color: #1f1f1f; /* Dark header background color */
}

.dark-mode .side-bar {
  background-color: #1f1f1f; /* Dark side bar background color */
}

.dark-mode .box {
  background-color: #1f1f1f; /* Dark box background color */
}

.dark-mode .box,
.dark-mode .box h3,
.dark-mode .box p,
.dark-mode .inline-btn,
.dark-mode .flex a {
  color: #ffffff; /* White text color */
}

.header .flex .search-form button:hover {
  color: var(--main-color);
}

.header .flex .icons div {
  font-size: 2rem;
  color: var(--black);
  background-color: var(--light-bg);
  border-radius: 0.5rem;
  height: 4.5rem;
  width: 4.5rem;
  line-height: 4.5rem;
  cursor: pointer;
  text-align: center;
  margin-left: 0.7rem;
}

.header .flex .icons div:hover {
  background-color: #94d500;
  color: var(--white);
}

.header .flex .profile {
  position: absolute;
  top: 120%;
  right: 2rem;
  background-color: var(--white);
  border-radius: 0.5rem;
  padding: 1.5rem;
  text-align: center;
  overflow: hidden;
  transform-origin: top right;
  transform: scale(0);
  transition: 0.2s linear;
  width: 30rem;
}

.header .flex .profile.active {
  transform: scale(1);
}

.header .flex .profile .image {
  height: 10rem;
  width: 10rem;
  border-radius: 50%;
  object-fit: contain;
  margin-bottom: 1rem;
}

.header .flex .profile .name {
  font-size: 2rem;
  color: var(--black);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.header .flex .profile .role {
  font-size: 1.8rem;
  color: var(--light-color);
}

#search-btn {
  display: none;
}

.side-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 30rem;
  background-color: var(--white);
  height: 100vh;
  border-right: var(--border);
  z-index: 1200;
}

.side-bar #close-btn {
  text-align: right;
  padding: 2rem;
  display: none;
}

.side-bar #close-btn i {
  text-align: right;
  font-size: 2.5rem;
  background: var(--red);
  border-radius: 0.5rem;
  color: var(--white);
  cursor: pointer;
  height: 4.5rem;
  width: 4.5rem;
  line-height: 4.5rem;
  text-align: center;
}

.side-bar #close-btn i:hover {
  background-color: var(--black);
}

.side-bar .profile {
  display: flex;
  flex-direction: column;
  padding: 3rem 2rem;
  text-align: center;
  position: relative;
  text-align: center;
}

.edit-button {
  background-color: transparent;
  border: none;
  color: #94d500;
  cursor: pointer;
  font-size: 24px;
}

.edit-button:hover {
  color: #486603;
}

.file-upload-widget {
  margin-top: 10px;
}

.file-input {
  display: block;
  margin: 10px auto;
}

.button-group {
  display: flex;
  justify-content: space-between;
}

.upload-button,
.cancel-button {
  background-color: #94d500;
  color: white;
  border: none;
  padding: 5px 10px;
  margin: 5px;
  cursor: pointer;
  border-radius: 5px;
}

.upload-button:hover,
.cancel-button:hover {
  background-color: #486603;
}

.cancel-button {
  background-color: #dc3545;
}

.cancel-button:hover {
  background-color: #c82333;
}

.name {
  margin-top: 10px;
}

.side-bar .profile .image {
  height: 10rem;
  width: 10rem;
  border-radius: 50%;
  object-fit: contain;
  margin-bottom: 1rem;
}

.side-bar .profile .name {
  font-size: 2rem;
  color: var(--black);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.side-bar .profile .role {
  font-size: 1.8rem;
  color: var(--light-color);
}

.side-bar .navbar a {
  display: block;
  padding: 2rem;
  font-size: 2rem;
}

.side-bar .navbar a i {
  margin-right: 1.5rem;
  color: var(--main-color);
  transition: 0.2s linear;
}

.side-bar .navbar a span {
  color: var(--light-color);
}

.side-bar .navbar a:hover {
  background-color: var(--light-bg);
}

.side-bar .navbar a:hover i {
  margin-right: 2.5rem;
}

.side-bar.active {
  left: -30rem;
}

.home-grid .box-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, 27.5rem);
  gap: 1.5rem;
  justify-content: center;
  align-items: flex-start;
}

.home-grid .box-container .box {
  background-color: var(--white);
  border-radius: 0.5rem;
  padding: 2rem;
}

.home-grid .box-container .box .title {
  font-size: 2rem;
  color: var(--black);
  text-transform: capitalize;
}

.home-grid .box-container .box .flex {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-top: 2rem;
}

.home-grid .box-container .flex a {
  background-color: var(--light-bg);
  border-radius: 0.5rem;
  padding: 1rem 1.5rem;
  font-size: 1.5rem;
}

.home-grid .box-container .flex a i {
  margin-right: 1rem;
  color: var(--black);
}

.home-grid .box-container .flex a span {
  color: var(--light-color);
}

.home-grid .box-container .flex a:hover {
  background-color: #94d500;
}

.home-grid .box-container .flex a:hover span {
  color: var(--white);
}

.home-grid .box-container .flex a:hover i {
  color: var(--white);
}

.home-grid .box-container .tutor {
  padding: 1rem 0;
  font-size: 1.8rem;
  color: var(--light-color);
  line-height: 2;
}

.home-grid .box-container .likes {
  color: var(--light-color);
  font-size: 1.7rem;
  margin-top: 1.5rem;
}

.home-grid .box-container .likes span {
  color: var(--main-color);
}

.about .row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.about .row .image {
  flex: 1 1 40rem;
}

.about .row .image img {
  width: 100%;
  height: 50rem;
}

.about .row .content {
  flex: 1 1 40rem;
}

.about .row .content p {
  font-size: 1.7rem;
  line-height: 2;
  color: var(--black);
  padding: 1rem 0;
}

.about .row .content h3 {
  font-size: 3rem;
  color: var(--black);
  text-transform: capitalize;
}

.about .box-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(27rem, 1fr));
  gap: 1.5rem;
  justify-content: center;
  align-items: flex-start;
  margin-top: 3rem;
}

.about .box-container .box {
  border-radius: 0.5rem;
  background-color: var(--white);
  padding: 2rem;
  display: flex;
  align-items: center;
  gap: 2.5rem;
}

.about .box-container .box i {
  font-size: 4rem;
  color: var(--main-color);
}

.about .box-container .box h3 {
  font-size: 2.5rem;
  color: var(--black);
  margin-bottom: 0.3rem;
}

.about .box-container .box p {
  font-size: 1.7rem;
  color: var(--light-color);
}

.reviews .box-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  gap: 1.5rem;
  justify-content: center;
  align-items: flex-start;
}

.reviews .box-container .box {
  border-radius: 0.5rem;
  background-color: var(--white);
  padding: 2rem;
}

.reviews .box-container .box p {
  line-height: 1.7;
  font-size: 1.7rem;
  color: var(--light-color);
}

.reviews .box-container .box .student {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.reviews .box-container .box .student img {
  height: 5rem;
  width: 5rem;
  object-fit: cover;
  border-radius: 50%;
}

.reviews .box-container .box .student h3 {
  font-size: 2rem;
  color: var(--black);
  margin-bottom: 0.3rem;
}

.reviews .box-container .box .student .stars {
  font-size: 1.5rem;
  color: var(--orange);
}

.courses .box-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  gap: 1.5rem;
  justify-content: center;
  align-items: flex-start;
}

.courses .box-container .box {
  border-radius: 0.5rem;
  background-color: var(--white);
  padding: 2rem;
}

.courses .box-container .box .tutor {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 2.5rem;
}

.courses .box-container .box .tutor img {
  height: 5rem;
  width: 5rem;
  border-radius: 50%;
  object-fit: cover;
}

.courses .box-container .box .tutor h3 {
  font-size: 1.8rem;
  color: var(--black);
  margin-bottom: 0.2rem;
}

.courses .box-container .box .tutor span {
  font-size: 1.3rem;
  color: var(--light-color);
}

.courses .box-container .box .thumb {
  position: relative;
}

.courses .box-container .box .thumb span {
  position: absolute;
  top: 1rem;
  left: 1rem;
  border-radius: 0.5rem;
  padding: 0.5rem 1.5rem;
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
  font-size: 1.5rem;
}

.courses .box-container .box .thumb img {
  width: 100%;
  height: 20rem;
  object-fit: cover;
  border-radius: 0.5rem;
}

.courses .box-container .box .title {
  font-size: 2rem;
  color: var(--black);
  padding-bottom: 0.5rem;
  padding-top: 1rem;
}

.courses .more-btn {
  text-align: center;
  margin-top: 2rem;
}

.playlist-details .row {
  display: flex;
  align-items: flex-end;
  gap: 3rem;
  flex-wrap: wrap;
  background-color: var(--white);
  padding: 2rem;
}

.playlist-details .row .column {
  flex: 1 1 40rem;
}

.playlist-details .row .column .save-playlist {
  margin-bottom: 1.5rem;
}

.playlist-details .row .column .save-playlist button {
  border-radius: 0.5rem;
  background-color: var(--light-bg);
  padding: 1rem 1.5rem;
  cursor: pointer;
}

.playlist-details .row .column .save-playlist button i {
  font-size: 2rem;
  color: var(--black);
  margin-right: 0.8rem;
}

.playlist-details .row .column .save-playlist button span {
  font-size: 1.8rem;
  color: var(--light-color);
}

.playlist-details .row .column .save-playlist button:hover {
  background-color: var(--black);
}

.playlist-details .row .column .save-playlist button:hover i {
  color: var(--white);
}

.playlist-details .row .column .save-playlist button:hover span {
  color: var(--white);
}

.playlist-details .row .column .thumb {
  position: relative;
}

.playlist-details .row .column .thumb span {
  font-size: 1.8rem;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 0.5rem;
  position: absolute;
  top: 1rem;
  left: 1rem;
  padding: 0.5rem 1.5rem;
}

.playlist-details .row .column .thumb img {
  height: 30rem;
  width: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
}

.playlist-details .row .column .tutor {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-bottom: 2rem;
}

.playlist-details .row .column .tutor img {
  height: 7rem;
  width: 7rem;
  border-radius: 50%;
  object-fit: cover;
}

.playlist-details .row .column .tutor h3 {
  font-size: 2rem;
  color: var(--black);
  margin-bottom: 0.2rem;
}

.playlist-details .row .column .tutor span {
  font-size: 1.5rem;
  color: var(--light-color);
}

.playlist-details .row .column .details h3 {
  font-size: 2rem;
  color: var(--black);
  text-transform: capitalize;
}

.playlist-details .row .column .details p {
  padding: 1rem 0;
  line-height: 2;
  font-size: 1.8rem;
  color: var(--light-color);
}

.playlist-videos .box-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  gap: 1.5rem;
  justify-content: center;
  align-items: flex-start;
}

.playlist-videos .box-container .box {
  background-color: var(--white);
  border-radius: 0.5rem;
  padding: 2rem;
  position: relative;
}

.playlist-videos .box-container .box i {
  position: absolute;
  top: 2rem;
  left: 2rem;
  right: 2rem;
  height: 20rem;
  border-radius: 0.5rem;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 5rem;
  color: #fff;
  display: none;
}

.playlist-videos .box-container .box:hover i {
  display: flex;
}

.playlist-videos .box-container .box img {
  width: 100%;
  height: 20rem;
  object-fit: cover;
  border-radius: 0.5rem;
}

.playlist-videos .box-container .box h3 {
  margin-top: 1.5rem;
  font-size: 2rem;
  color: var(--black);
}

.playlist-videos .box-container .box:hover h3 {
  color: var(--main-color);
}

.watch-video .video-container {
  background-color: var(--white);
  border-radius: 0.5rem;
  padding: 2rem;
}

.watch-video .video-container .video {
  position: relative;
  margin-bottom: 1.5rem;
}

.watch-video .video-container .video video {
  border-radius: 0.5rem;
  width: 100%;
  object-fit: contain;
  background-color: #000;
}

.watch-video .video-container .title {
  font-size: 2rem;
  color: var(--black);
}

.watch-video .video-container .info {
  display: flex;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
  border-bottom: var(--border);
  padding-bottom: 1.5rem;
  gap: 2.5rem;
  align-items: center;
}

.watch-video .video-container .info p {
  font-size: 1.6rem;
}

.watch-video .video-container .info p span {
  color: var(--light-color);
}

.watch-video .video-container .info i {
  margin-right: 1rem;
  color: var(--main-color);
}

.watch-video .video-container .tutor {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-bottom: 1rem;
}

.watch-video .video-container .tutor img {
  border-radius: 50%;
  height: 5rem;
  width: 5rem;
  object-fit: cover;
}

.watch-video .video-container .tutor h3 {
  font-size: 2rem;
  color: var(--black);
  margin-bottom: 0.2rem;
}

.watch-video .video-container .tutor span {
  font-size: 1.5rem;
  color: var(--light-color);
}

.watch-video .video-container .flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
}

.watch-video .video-container .flex button {
  border-radius: 0.5rem;
  padding: 1rem 1.5rem;
  font-size: 1.8rem;
  cursor: pointer;
  background-color: var(--light-bg);
}

.watch-video .video-container .flex button i {
  margin-right: 1rem;
  color: var(--black);
}

.watch-video .video-container .flex button span {
  color: var(--light-color);
}

.watch-video .video-container .flex button:hover {
  background-color: var(--black);
}

.watch-video .video-container .flex button:hover i {
  color: var(--white);
}

.watch-video .video-container .flex button:hover span {
  color: var(--white);
}

.watch-video .video-container .description {
  line-height: 1.5;
  font-size: 1.7rem;
  color: var(--light-color);
  margin-top: 2rem;
}

.comments .add-comment {
  background-color: var(--white);
  border-radius: 0.5rem;
  padding: 2rem;
  margin-bottom: 3rem;
}

.comments .add-comment h3 {
  font-size: 2rem;
  color: var(--black);
  margin-bottom: 1rem;
}

.comments .add-comment textarea {
  height: 20rem;
  resize: none;
  background-color: var(--light-bg);
  border-radius: 0.5rem;
  border: var(--border);
  padding: 1.4rem;
  font-size: 1.8rem;
  color: var(--black);
  width: 100%;
  margin: 0.5rem 0;
}

.comments .box-container {
  display: grid;
  gap: 2.5rem;
  background-color: var(--white);
  padding: 2rem;
  border-radius: 0.5rem;
}

.comments .box-container .box .user {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.comments .box-container .box .user img {
  height: 5rem;
  width: 5rem;
  border-radius: 50%;
}

.comments .box-container .box .user h3 {
  font-size: 2rem;
  color: var(--black);
  margin-bottom: 0.2rem;
}

.comments .box-container .box .user span {
  font-size: 1.5rem;
  color: var(--light-color);
}

.comments .box-container .box .comment-box {
  border-radius: 0.5rem;
  background-color: var(--light-bg);
  padding: 1rem 1.5rem;
  white-space: pre-line;
  margin: 0.5rem 0;
  font-size: 1.8rem;
  color: var(--black);
  line-height: 1.5;
  position: relative;
  z-index: 0;
}

.comments .box-container .box .comment-box::before {
  content: "";
  position: absolute;
  top: -1rem;
  left: 1.5rem;
  background-color: var(--light-bg);
  height: 1.2rem;
  width: 2rem;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.teachers .search-tutor {
  margin-bottom: 2rem;
  border-radius: 0.5rem;
  background-color: var(--white);
  padding: 1.5rem 2rem;
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.teachers .search-tutor input {
  width: 100%;
  background: none;
  font-size: 1.8rem;
  color: var(--black);
}

.teachers .search-tutor button {
  font-size: 2rem;
  color: var(--black);
  cursor: pointer;
  background: none;
}

.teachers .search-tutor button:hover {
  color: var(--main-color);
}

.teachers .box-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  gap: 1.5rem;
  justify-content: center;
  align-items: flex-start;
}

.teachers .box-container .box {
  background-color: var(--white);
  border-radius: 0.5rem;
  padding: 2rem;
}

.teachers .box-container .offer {
  text-align: center;
}

.teachers .box-container .offer h3 {
  font-size: 2.5rem;
  color: var(--black);
  text-transform: capitalize;
  padding-bottom: 0.5rem;
}

.teachers .box-container .offer p {
  line-height: 1.7;
  padding: 0.5rem 0;
  color: var(--light-color);
  font-size: 1.7rem;
}

.teachers .box-container .box .tutor {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-bottom: 1.5rem;
}

.teachers .box-container .box .tutor img {
  height: 5rem;
  width: 5rem;
  border-radius: 50%;
}

.teachers .box-container .box .tutor h3 {
  font-size: 2rem;
  color: var(--black);
  margin-bottom: 0.2rem;
}

.teachers .box-container .box .tutor span {
  font-size: 1.6rem;
  color: var(--light-color);
}

.teachers .box-container .box p {
  padding: 0.5rem 0;
  font-size: 1.7rem;
  color: var(--light-color);
}

.teachers .box-container .box p span {
  color: var(--main-color);
}

.teacher-profile .details {
  text-align: center;
  background-color: var(--white);
  border-radius: 0.5rem;
  padding: 2rem;
}

.teacher-profile .details .tutor img {
  height: 10rem;
  width: 10rem;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 1rem;
}

.teacher-profile .details .tutor h3 {
  font-size: 2rem;
  color: var(--black);
}

.teacher-profile .details .tutor span {
  color: var(--light-color);
  font-size: 1.7rem;
}

.teacher-profile .details .flex {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-top: 2rem;
}

.teacher-profile .details .flex p {
  flex: 1 1 20rem;
  border-radius: 0.5rem;
  background-color: var(--light-bg);
  padding: 1.2rem 2rem;
  font-size: 1.8rem;
  color: var(--light-color);
}

.teacher-profile .details .flex p span {
  color: var(--main-color);
}

.user-profile .info {
  background-color: var(--white);
  border-radius: 0.5rem;
  padding: 2rem;
}

.user-profile .info .user {
  text-align: center;
  margin-bottom: 2rem;
  padding: 1rem;
}

.user-profile .info .user img {
  height: 10rem;
  width: 10rem;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 1rem;
}

.user-profile .info .user h3 {
  font-size: 2rem;
  color: var(--black);
}

.user-profile .info .user p {
  font-size: 1.7rem;
  color: var(--light-color);
  padding: 0.3rem 0;
}

.user-profile .info .box-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.user-profile .info .box-container .box {
  background-color: var(--light-bg);
  border-radius: 0.5rem;
  padding: 2rem;
  flex: 1 1 25rem;
}

.user-profile .info .box-container .box .flex {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-bottom: 1rem;
}

.user-profile .info .box-container .box .flex i {
  font-size: 2rem;
  color: var(--white);
  background-color: var(--black);
  text-align: center;
  border-radius: 0.5rem;
  height: 5rem;
  width: 5rem;
  line-height: 4.9rem;
}

.user-profile .info .box-container .box .flex span {
  font-size: 2.5rem;
  color: var(--main-color);
}

.user-profile .info .box-container .box .flex p {
  color: var(--light-color);
  font-size: 1.7rem;
}

.contact .row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.contact .row .image {
  flex: 1 1 50rem;
}

.contact .row .image img {
  width: 100%;
}

.contact .row form {
  flex: 1 1 30rem;
  background-color: var(--white);
  padding: 2rem;
  text-align: center;
}

.contact .row form h3 {
  margin-bottom: 1rem;
  text-transform: capitalize;
  color: var(--black);
  font-size: 2.5rem;
}

.contact .row form .box {
  width: 100%;
  border-radius: 0.5rem;
  background-color: var(--light-bg);
  margin: 1rem 0;
  padding: 1.4rem;
  font-size: 1.8rem;
  color: var(--black);
}

.contact .row form textarea {
  height: 20rem;
  resize: none;
}

.contact .box-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  gap: 1.5rem;
  justify-content: center;
  align-items: flex-start;
  margin-top: 3rem;
}

.contact .box-container .box {
  text-align: center;
  background-color: var(--white);
  border-radius: 0.5rem;
  padding: 3rem;
}

.contact .box-container .box i {
  font-size: 3rem;
  color: var(--main-color);
  margin-bottom: 1rem;
}

.contact .box-container .box h3 {
  font-size: 2rem;
  color: var(--black);
  margin: 1rem 0;
}

.contact .box-container .box a {
  display: block;
  padding-top: 0.5rem;
  font-size: 1.8rem;
  color: var(--light-color);
}

.contact .box-container .box a:hover {
  text-decoration: underline;
  color: var(--black);
}

.form-container {
  min-height: calc(100vh - 20rem);
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-container form {
  background-color: var(--white);
  border-radius: 0.5rem;
  padding: 2rem;
  width: 50rem;
}

.form-container form h3 {
  font-size: 2.5rem;
  text-transform: capitalize;
  color: var(--black);
  text-align: center;
}

.form-container form p {
  font-size: 1.7rem;
  color: var(--light-color);
  padding-top: 1rem;
}

.form-container form p span {
  color: var(--red);
}

.form-container form .box {
  font-size: 1.8rem;
  color: var(--black);
  border-radius: 0.5rem;
  padding: 1.4rem;
  background-color: var(--light-bg);
  width: 100%;
  margin: 1rem 0;
}

.footer {
  background-color: var(--white);
  border-top: var(--border);
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 1.3rem;
  padding: 1rem 2rem;
  color: var(--black);
  margin-top: 1rem;
  z-index: 1000;
  /* padding-bottom: 9.5rem; */
}

.footer span {
  color: var(--main-color);
}

.register {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.containerrr {
  width: 350px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.containerrr h2 {
  margin-bottom: 20px;
  font-size: 2rem;
}

.form-register_form {
  display: flex;
  flex-direction: column;
}

.form-register_form input {
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.btnprimary {
  background-color: #94d500;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.btnprimary:hover {
  background-color: #e74c3c;
}

small {
  margin-top: 10px;
  display: block;
  text-align: center;
  font-size: 1.5rem;
}

small a {
  color: #e74c3c;
}

.topic-title {
  cursor: pointer;
}

.quiz ul {
  background-color: white;
}

/* QuizPage.css */

.quiz {
  margin: 20px;
}

.quiz-btn {
  margin: 10px 0;
  padding: 10px;
  font-size: 2rem;
  color: black;
  font-weight: bold;
  border: none;
  cursor: pointer;
}

.notes {
  margin-top: 20px;
}

.question-block {
  margin-bottom: 20px;
}

.options {
  display: flex;
  flex-direction: column; /* This ensures the options are displayed vertically */
  margin-bottom: 10px;
}

.option {
  margin-bottom: 5px;
}

.feedback {
  margin-top: 10px;
}

.feedback.correct {
  color: green;
}

.feedback.incorrect {
  color: red;
}

button[type="button"] {
  margin-top: 10px;
  padding: 10px;
  background-color: #94d500;
  color: #fff;
  border: none;
  cursor: pointer;
}

button[type="button"]:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.notes h2 {
  font-size: 2rem;
  background-color: #fff;
}
.notes p {
  font-size: 1.8rem;
}
.notes ul {
  font-size: 1.7rem;
}

.sub {
  font-size: 2rem;
}
.ptag {
  font-weight: bold;
}

.question h3 {
  font-size: 2rem;
}

.option {
  font-size: 1.8rem;
}

.undercontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 50px;
  background-color: #f4f4f4;
  color: #333;
  border-radius: 10px;
  max-width: 600px;
  margin: 0 auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.undertitle {
  color: #94d500;
}

.undermessage {
  font-size: 18px;
  margin: 20px 0;
}

.spinner {
  --size: 30px;
  --first-block-clr: #e74c3c;
  --second-block-clr: #94d500;
  --clr: #111;
  width: 100px;
  height: 100px;
  position: relative;
}

.spinner::after,
.spinner::before {
  box-sizing: border-box;
  position: absolute;
  content: "";
  width: var(--size);
  height: var(--size);
  top: 50%;
  animation: up 2.4s cubic-bezier(0, 0, 0.24, 1.21) infinite;
  left: 50%;
  background: var(--first-block-clr);
}

.spinner::after {
  background: var(--second-block-clr);
  top: calc(50% - var(--size));
  left: calc(50% - var(--size));
  animation: down 2.4s cubic-bezier(0, 0, 0.24, 1.21) infinite;
}

@keyframes down {
  0%,
  100% {
    transform: none;
  }

  25% {
    transform: translateX(100%);
  }

  50% {
    transform: translateX(100%) translateY(100%);
  }

  75% {
    transform: translateY(100%);
  }
}

@keyframes up {
  0%,
  100% {
    transform: none;
  }

  25% {
    transform: translateX(-100%);
  }

  50% {
    transform: translateX(-100%) translateY(-100%);
  }

  75% {
    transform: translateY(-100%);
  }
}

@media (max-width: 1200px) {
  body {
    padding-left: 0;
  }

  .side-bar {
    position: fixed;
    top: 0;
    left: -300px;
    width: 300px;
    height: 100%;
    background-color: #333;
    transition: left 0.3s ease-in-out;
    z-index: 1000;
  }

  .side-bar.open {
    left: 0;
  }

  .side-bar #close-btn {
    cursor: pointer;
    color: #fff;
    padding: 10px;
    text-align: right;
  }

  .side-bar .profile {
    padding: 20px;
    border-bottom: 1px solid #666;
  }

  .side-bar .navbar {
    padding: 20px;
  }

  .side-bar .navbar a {
    display: block;
    color: #fff;
    text-decoration: none;
    margin-bottom: 10px;
    transition: color 0.3s ease;
  }

  .side-bar .navbar a:hover {
    color: #ff0; /* Change text color on hover */
  }

  .side-bar #close-btn {
    display: block;
  }

  .side-bar.active {
    left: 0;
    box-shadow: 0 0 0 100vw rgba(0, 0, 0, 0.8);
    border-right: 0;
  }
}

@media (max-width: 991px) {
  html {
    font-size: 55%;
  }
}

@media (max-width: 768px) {
  #search-btn {
    display: inline-block;
  }

  .header .flex .search-form {
    position: absolute;
    top: 99%;
    left: 0;
    right: 0;
    border-top: var(--border);
    border-bottom: var(--border);
    background-color: var(--white);
    border-radius: 0;
    width: auto;
    padding: 2rem;
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    transition: 0.2s linear;
  }

  .header .flex .search-form.active {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}

@media (max-width: 450px) {
  html {
    font-size: 50%;
  }

  .flex-btn {
    gap: 0;
    flex-flow: column;
  }

  .home-grid .box-container {
    grid-template-columns: 1fr;
  }

  .about .row .image img {
    height: 25rem;
  }
}

/* modal.css */
/* styles.css */
.modal {
  background: black;
  border-radius: 8px;
  padding: 20px;
  max-width: 500px;
  margin: auto;
  text-align: center;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 40px;
}

.btn-primary {
  background-color: red;
  color: white;
  font-size: 14px;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.btn-secondary {
  background-color: gray;
  color: white;
  font-size: 14px;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}
.modal-box {
  background: white;
  color: #121212;
  padding: 40px 64px;
  border-radius: 6px;
  font-size: 14px;
}

.searchQuery {
  padding: 10px 20px;
  font-size: 1.5rem;
}

.search-form {
  display: flex;
  align-items: center;
}

.search-form input {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.search-form button {
  margin-left: 5px;
  padding: 10px;
  border: none;
  background: none;
  cursor: pointer;
}

.search-results-container {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.search-results {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 1.5rem;
}

.search-results li {
  padding: 10px;
  border-bottom: 1px solid #eee;
  font-size: 1.5rem;
}

.search-results li a {
  text-decoration: none;
  color: #333;
  font-size: 1.5rem;
}

.search-results li a:hover {
  text-decoration: underline;
}

.card {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.dismiss {
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  background-color: #fff;
  color: black;
  border: 2px solid #d1d5db;
  font-size: 1rem;
  font-weight: 300;
  width: 30px;
  height: 30px;
  border-radius: 7px;
  transition: 0.3s ease;
}

.dismiss:hover {
  background-color: #ee0d0d;
  border: 2px solid #ee0d0d;
  color: #fff;
}

.header {
  padding: 1.25rem 1rem 1rem 1rem;
}

.image {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  background-color: #e2feee;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  border-radius: 9999px;
  /* animation: animate .6s linear alternate-reverse infinite; */
  transition: 0.6s ease;
}

.image svg {
  color: #0afa2a;
  width: 2rem;
  height: 2rem;
}

.content {
  margin-top: 0.75rem;
  text-align: center;
  color: var(--black);
}

.title {
  color: #066e29;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.5rem;
}

.message {
  margin-top: 0.5rem;
  color: #595b5f;
  font-size: 1.25rem;
  line-height: 1.25rem;
}

.actions {
  margin: 0.75rem 1rem;
}

.history {
  display: inline-flex;
  padding: 0.5rem 1rem;
  background-color: #1aa06d;
  color: #ffffff;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  justify-content: center;
  width: 100%;
  border-radius: 0.375rem;
  border: none;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.track {
  display: inline-flex;
  margin-top: 0.75rem;
  padding: 0.5rem 1rem;
  color: #242525;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  justify-content: center;
  width: 100%;
  border-radius: 0.375rem;
  border: 1px solid #d1d5db;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

@keyframes animate {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.09);
  }
}

.btnprimary:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

.btnprimary:disabled::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  border: 2px solid #fff;
  border-top: 2px solid #007bff;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

/* change password */

.change-password-form {
  margin-top: 1rem;
}

.change-password-form input {
  display: block;
  width: 100%;
  margin-bottom: 1rem;
  padding: 0.5rem;
}

.change-password-form button {
  padding: 0.5rem 1rem;
  background-color: #4caf50;
  color: white;
  border: none;
  cursor: pointer;
}

.change-password-form button:hover {
  background-color: #45a049;
}

.error {
  color: red;
}

.success {
  color: green;
}

/* home.css */

/* Dropdown container */
.dropdown {
  position: absolute;
  background-color: white;
  border: 1px solid #ddd;
  padding: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
  transition: opacity 0.3s ease, transform 0.3s ease;
  opacity: 0;
  transform: translateY(-10px);
  pointer-events: none; /* Hide interaction when not visible */
}

/* When the dropdown is active, show it */
.dropdown.active {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto; /* Enable interaction when visible */
}

.info {
  display: flex;
  align-items: center;
  position: relative;
}

.box .title {
  line-height: 1.3;
}
