@import url("https://fonts.googleapis.com/css?family=Lato:400,700");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.alll-container {
  background-image: url("../../assets/image3.jpg");
  background-size: cover;
  background-attachment: fixed;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100vh;
  padding-top: 20%;
  padding-right: 50px;
  color: #fff;
}

.alll-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
z-index: -1;
}



.containerr {
  max-width: 80%;
  margin: auto;
}

.roww {
  width: 50%;
}

.primary-btnn {
  background-color: #e74c3c;
  color: #fff;
  padding: 17px 15px;
  font-weight: 600;
  border: none;
  margin: 5px 5px 5px 5px;
  border-radius: 3px;
  cursor: pointer;
  transition: 0.5s;
  text-decoration: none;
}

.buttonn :hover {
  box-shadow: none;
}

.roww h1 {
  font-size: 34px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #94d500;
  text-transform: uppercase;
}

.roww p {
  line-height: 30px;
  font-size: 20px;
  color: white;
}

/* Media Queries for Mobile */
@media screen and (max-width: 768px) {
  .margin {
    height: 40vh;
  }
  .alll-container {
    background-position: right;
    padding-top: 50%;
    height: 100vh;
  }

  .roww {
    width: 100%;
    text-align: right; 
  }

  .roww h1 {
    font-size: 20px;
    font-weight: bold;
  }

  .roww p {
    font-size: 14px;
    margin: 15px 0;
  }

  .primary-btnn {
    padding: 8px 16px;
    font-size: 14px;
  }
}
