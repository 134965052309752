
  .navbarr {
    display: flex;
    justify-content: center;
    margin-top: 40px; 
    margin: 0 30px;
    position: sticky;
    top:0; left:0; right: 0;
    z-index: 1000;
    
    
  }

  .navbarl{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 1.5rem 2rem;
    margin-right:80px ;
  }
  
  .navbarr-links ul {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
  }
  
  .navbarr-links li {
    margin-right: 60px; 
  }
  

  .navbarr-links a {
    text-decoration: none;
    color: white; 
    font-weight: bold;
    
    font-size: 20px;
  }

  .navbarr-links a:hover{
color: #94d500;
  }
  

  @media (max-width: 768px) {
    .navbarl {
      flex-direction: row;
      align-items: center;
    }
  
    .navbarr-links ul {
      flex-direction: row;
      gap: 10px;
    }
  
    .navbarr-links ul li a {
      font-size: 14px;
    }
  }