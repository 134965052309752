.profile-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    font-size: 1.5rem;
    background-color: rgba(255, 255, 255, 0.667);
  }
  
  .header-section {
    text-align: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }

  .header-section .image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;
  }

  .header-section h1 {
    font-size: 24px;
    margin: 10px 0;
  }
  
  .profile-picture {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-bottom: 10px;
  }
  
  .bio {
    font-style: italic;
    color: #666;
  }
  
  .personal-info, .academic-info, .achievements-badges, .learning-activities, .social-interaction, .settings-privacy, .personalized-recommendations {
    margin-bottom: 20px;
  }
  
  h3 {
    border-bottom: 2px solid #ddd;
    padding-bottom: 5px;
    margin-bottom: 10px;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  li {
    margin-bottom: 5px;
  }
  
  button {
    margin-right: 10px;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .progress {
    width: 100%;
  }
  